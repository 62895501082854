<template>
  <div class="m-4">
    <div class="mt-10">
      <span>{{ title }}</span> 
    </div>
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th scope="col">Description</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Average</td>
          <td>{{ promedio }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Array, Number],
      required: true,
    },
    title: {
      type: String,
      required: true, // Ahora el título es obligatorio
    },
  },
  data() {
    return {
      promedio: 0, // Variable para almacenar el promedio de AVG_TOTAL
    };
  },
  watch: {
    data: {
      immediate: true, // Ejecutar el watcher inmediatamente al crear el componente
      handler(newVal) {
        this.calculatePromedio(newVal);
      },
    },
  },
  methods: {
    // Método para calcular el promedio de AVG_TOTAL
    calculatePromedio(data) {
      if (Array.isArray(data)) {
        const total = data.reduce((acc, cur) => acc + parseFloat(cur.AVG_TOTAL), 0);
        this.promedio = (total / data.length).toFixed(1); // Calcula el promedio y lo fija a dos decimales
      } else {
        this.promedio = data.toFixed(1);
      }
    },
  },
  mounted() {
    this.calculatePromedio(this.data); // Calcula el promedio al montar el componente
  },
};
</script>

<style scoped></style>