<template>
  <b-row>
    <b-col>
      <b-card class="tarjeta">
        <apexcharts
          width="700"
          height="420"
          type="line"
          :options="chartOptions"
          :series="series"></apexcharts>
      </b-card>
    </b-col>
    <b-col>
      <indicatorChart :data="datosAVG" :title="'AVG TOTAL'" />
      <indicatorChart :data="datosNc" :title="'NC'" :indicator="false" />
      <indicatorChart :data="datosNp" :title="'NP'" :indicator="false" />
    </b-col>
  </b-row>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import indicatorChart from './indicatorChart'; // Ajusta la ruta según tu estructura

export default {
  name: 'Chart',
  components: {
    apexcharts: VueApexCharts,
    indicatorChart,
    // indicatorNcNpChart
  },
  props: {
    average: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      datosAVG: [],
      datosNc: 0,
      datosNp: 0,
      chartOptions: {
        chart: {
          id: 'financial-details',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Financial Details',
          },
        },
        title: {
          text: 'Averages',
          align: 'center',
        },
        stroke: {
          curve: 'smooth',
        },
      },
      series: [
        {
          name: 'Average Total',
          data: [],
        },
        {
          name: 'Minimum',
          data: [],
        },
        {
          name: 'Maximum',
          data: [],
        },
      ],
    };
  },
  watch: {
    average: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.total_daily) {
          this.updateChart(newVal.total_daily);
        }
      },
    },
  },
  methods: {
    updateChart(totalDaily) {
      const categories = totalDaily.map(
        (entry) => `${entry.year}-${entry.month}-${entry.day}`
      );
      const avgTotalData = totalDaily.map((entry) => this.parseValue(entry.AVG_TOTAL));
      const minData = totalDaily.map((entry) => this.parseValue(entry.MIN));
      const maxData = totalDaily.map((entry) => this.parseValue(entry.MAX));

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories,
        },
      };

      this.series = [
        {
          name: 'Average Total',
          data: avgTotalData,
        },
        {
          name: 'Minimum',
          data: minData,
        },
        {
          name: 'Maximum',
          data: maxData,
        },
      ];

      this.datosNc = parseFloat(this.average.nc.average) || 0;
      this.datosNp = parseFloat(this.average.np.average) || 0;
      this.datosAVG = totalDaily;
    },
    parseValue(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    },
  },
};
</script>

<style scoped></style>
