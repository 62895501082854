<template>
  <div>
    <!-- Primera fila -->
    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <h1 style="text-align: center">Admin Memberships</h1>
          <filterData ref="filtro" @dataReceived="handleDataReceived" :page="page" />
        </b-card>
      </b-col>
    </b-row>

    <!-- Segunda fila -->
    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <ncNpChart
            :data="ncNpData"
            @NC-show-modal="ncShowAppointmentModal = true"
            @total-show-modal="totalShowAppointmentModal = true"
            @NP-show-modal="npShowAppointmentModal = true" />
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <indicatorNcNpChart :data="averages" :title="'NC Np'"> </indicatorNcNpChart>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <appointmentChart
              :data="appointmentsData"
              @show-modal="showAppointmentModal = true" />
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <membershipChart
              :data="membershipsData"
              @show-modal="showMembershipModal = true" />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <averages :average="averages" />
          </div>
        </b-card>
      </b-col>
      <!-- <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <patientsPerDayChart :patientsPerDay="patientsPerDay" />
          </div>
        </b-card>
      </b-col> -->
    </b-row>

    <b-row>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <patientsPerDayNcNp
              :patientsPerDayNc="patientsPerDayNc"
              :patientsPerDayNp="patientsPerDayNp" />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <cashCcChart :cashCc="cashCc" />
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="tarjeta">
          <div class="d-flex justify-content-center">
            <totalsChart :totals="totalsData" />
          </div>
        </b-card>
      </b-col>
    </b-row> -->

    <!-- Modal para total NC y NP -->
    <b-modal
      v-model="totalShowAppointmentModal"
      title="Total NC y NP Details"
      size="xl"
      class="custom-modal">
      <appointmentDetails :appointment="totalNcNpData" />
    </b-modal>

    <!-- Modal para NC -->
    <b-modal
      v-model="ncShowAppointmentModal"
      title="NC Details"
      size="xl"
      class="custom-modal">
      <appointmentDetails :appointment="ncNpData.NC.data" />
    </b-modal>

    <!-- Modal para NP -->
    <b-modal
      v-model="npShowAppointmentModal"
      title="NP Details"
      size="xl"
      class="custom-modal">
      <appointmentDetails :appointment="ncNpData.NP.data" />
    </b-modal>

    <!-- Modal para Appointment -->
    <b-modal
      v-model="showAppointmentModal"
      title="Details total  Adjustments/ Gross production"
      size="xl"
      class="custom-modal">
      <appointmentDetails :appointment="appointmentsData.data" />
    </b-modal>

    <!-- Modal para Membership -->
    <b-modal
      v-model="showMembershipModal"
      title="Membership Details"
      size="xl"
      class="custom-modal">
      <membershipDetails :membership="membershipsData.data" />
    </b-modal>
  </div>
</template>

<script>
import { BContainer, BButton, BCard, BRow, BCol, BModal, BImg } from 'bootstrap-vue'
import filterData from './filterData.vue'
import appointmentChart from './appointmentChart.vue'
import membershipChart from './membershipChart.vue'
import appointmentDetails from './appointmentDetails.vue'
import membershipDetails from './membershipDetails.vue'
import averages from './average.vue'
// import patientsPerDayChart from './patientsPerDayChar'
import patientsPerDayNcNp from './patientsPerDayNcNp.vue'
// import cashCcChart from './cashCcChart'
// import totalsChart from './totalsChart'
import ncNpChart from './ncNpChart'
import indicatorNcNpChart from './indicatorNcNpChart'

export default {
  components: {
    filterData,
    appointmentChart,
    membershipChart,
    appointmentDetails,
    membershipDetails,
    averages,
    patientsPerDayNcNp,
    // patientsPerDayChart,
    // cashCcChart,
    // totalsChart,
    ncNpChart,
    indicatorNcNpChart,
  },
  data() {
    return {
      page: 1,
      data: [],
      averages: {
        nc: { total: 0, count: 0, average: 0 },
        np: { total: 0, count: 0, average: 0 },
      },
      totalsData: {
        expenses: 1,
        income: 1,
        net_income: 1,
      },
      patientsPerDay: [],
      patientsPerDayNc: [],
      patientsPerDayNp: [],
      cashCc: {
        cash: 1,
        cc: 1,
      },
      totalNcNpData: [],
      ncNpData: {
        NC: {
          data: [],
          values: {
            ajustment: 0,
            avg_ajustment: '0.00',
            avg_total: '0.00',
            cant: 0,
            total: 0,
          },
        },
        NP: {
          data: [],
          values: {
            ajustment: 0,
            avg_ajustment: '0.00',
            avg_total: '0.00',
            cant: 0,
            total: 0,
          },
        },
      },
      appointmentsData: {
        data: [],
        values: {
          ajustment: 0,
          avg_ajustment: '0.00',
          avg_total: '0.00',
          cant: 0,
          total: 0,
        },
      },
      membershipsData: {
        data: [],
        values: {
          avg_cash: '0.00',
          avg_total: '0.00',
          cant: 0,
          cash: 0,
          total: 0,
        },
      },
      showAppointmentModal: false,
      showMembershipModal: false,
      ncShowAppointmentModal: false,
      npShowAppointmentModal: false,
      totalShowAppointmentModal: false,
    }
  },
  methods: {
    handleDataReceived(data) {
      this.data = data
      this.averages = data.data.average
      this.patientsPerDay = data.data.patients_per_day
      this.patientsPerDayNc = data.data.patients_per_day_nc
      this.patientsPerDayNp = data.data.patients_per_day_np
      this.cashCc = data.data.cash_cc
      this.totalsData = data.data.totals
      this.appointmentsData = {
        data: data.appointments.data,
        values: data.appointments.values,
      }
      this.membershipsData = {
        data: data.memberships.data,
        values: data.memberships.values,
      }
      this.ncNpData = data.statistics
      this.totalNcNpData = [...this.ncNpData.NC.data, ...this.ncNpData.NP.data]
    },
  },
}
</script>
