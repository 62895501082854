<template>
  <b-container>
    <b-card header="Membership List" footer-tag="footer" :footer="`Page ${currentPage} of ${totalPages}`">
      <b-table :items="pagedMembership" :fields="fields" responsive="sm" :per-page="perPage" bordered>
        <template #cell(patient)="data">
          <div class="d-flex align-items-center">
            <!-- <b-img thumbnail :src="data.item.patient.avatar" alt="Avatar" width="50" height="50" class="mr-3"></b-img> -->
            <div>{{ data.item.patient.name }} {{ data.item.patient.lastname }}</div>
          </div>
        </template>
      </b-table>
      <b-pagination 
        v-model="currentPage" 
        :total-rows="membership.length" 
        :per-page="perPage"
        align="center"
        class="mt-3"
      />
    </b-card>
  </b-container>
</template>

<script>
export default {
  props: {
    membership: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10, // Número de registros por página
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'patient', label: 'Patient' },
        { key: 'count_visits_memberships', label: 'Count visits' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'end_date', label: 'End Date' },
        { key: 'memberships_parameters.name', label: 'Membership Name' },
        { key: 'memberships_parameters.price', label: 'Price' },
        { key: 'total', label: 'Total' },
        { key: 'cash', label: 'Cash' },
        { key: 'cc', label: 'Credit Card' }
      ]
    }

  },
  computed: {
    pagedMembership() {
      // Calcula los índices de los registros a mostrar en la página actual
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.membership.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.membership.length / this.perPage);
    }
  }
}
</script>

<style scoped>
.table {
  margin-bottom: 0;
}

.pagination {
  justify-content: center;
}
</style>