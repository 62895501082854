<template>
  <b-container>
    <div class="d-flex justify-content-center mb-3">
      <h3>Types of Visits / Discounts</h3>
    </div>
    <b-row>
      <b-col>
        <vue-apex-charts
          type="bar"
          width="900"
          height="410"
          :options="chartOptions"
          :series="series"
        />
      </b-col>
      <b-col class="d-flex justify-content-center flex-column align-items-center">
        <b-button variant="primary" class="m-2" @click="$emit('NC-show-modal')">
          NC Details
        </b-button>
        <b-button variant="primary" class="m-2" @click="$emit('NP-show-modal')">
          NP Details
        </b-button>
        <b-button variant="primary" class="m-2" @click="$emit('total-show-modal')">
          Total Details
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false, // Cambia a false si no deseas barras apiladas
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            distributed: true, // Colores distintos para cada barra
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 30, // Ajusta el valor para posicionar las etiquetas fuera de la barra
          style: {
            colors: ['#000000'], // Ajusta el color para mayor legibilidad
            fontSize: '12px',
            fontWeight: 'bold',
          },
          formatter: function (val) {
            return val.toFixed(0); // Mostrar sin decimales
          },
          position: 'right', // Posiciona las etiquetas a la derecha de las barras
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [], // Se actualizarán dinámicamente
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.updateChart(newData);
      },
    },
  },
  methods: {
    updateChart(data) {
      // Define tus categorías según los datos que recibes
      const categories = ['ajustment', 'total']

      // Extrae los datos correspondientes a NC y NP
      const seriesNC = categories.map(cat => data.NC.values[cat]);
      const seriesNP = categories.map(cat => data.NP.values[cat]);

      // Actualiza las series del gráfico
      this.series = [
        {
          name: 'NC',
          data: seriesNC,
        },
        {
          name: 'NP',
          data: seriesNP,
        },
      ];

      // Actualiza las categorías del eje X
      this.chartOptions.xaxis.categories = categories;
    },
  },
};
</script>

<style scoped>
.d-flex {
  gap: 1rem; /* Espacio entre botones y otros elementos */
}
</style>

