<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card
          header-tag="header"
          :footer="`Page ${currentPage} of ${totalPage}`"
          footer-tag="footer"
          class="mt-3"
        >
          <b-input-group class="mb-3">
            <b-form-input
              v-model="searchQuery"
              placeholder="Filter by Patient Name"
            ></b-form-input>
          </b-input-group>
          <b-table
            :items="pagedAppointments"
            :fields="fields"
            responsive="sm"
            bordered
            hover
            class="mb-3"
          ></b-table>
          <div class="d-flex justify-content-between align-items-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredAppointments.length"
              :per-page="perPage"
              align="center"
              class="mb-0"
            />
            <span class="ml-3">Total Records: {{ filteredAppointments.length }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    appointment: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'facilities.name', label: 'Facilities Name' },
        { key: 'patients.name', label: 'Patient Name' },
        { key: 'cash', label: 'Cash' },
        { key: 'ajustment', label: 'Adjustment' },
        { key: 'total', label: 'Total' },
        { key: 'reason_visit', label: 'Reason for Visit' },
        { key: 'visit_date', label: 'Visit Date' },
      ]
    }
  },
  computed: {
    filteredAppointments() {
      return this.appointment.filter(app => 
        app.patients.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    pagedAppointments() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredAppointments.slice(startIndex, endIndex);
    },
    totalPage() {
      return Math.ceil(this.filteredAppointments.length / this.perPage);
    }
  }
}
</script>

<style scoped>
.b-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.b-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
}
.b-pagination {
  margin-top: 10px;
}
</style>