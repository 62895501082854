<template>
  <div>
    <h3>Total  Adjustments/ Gross production</h3>
    <apexchart
      width="450"
      height="450"
      ref="chart"
      :options="chartOptions"
      :series="series"></apexchart>
    <div class="d-flex justify-content-center">
      <b-button variant="primary" @click="$emit('show-modal')"
        >Details total  Adjustments/ Gross production</b-button
      >
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        values: {
          ajustment: 0,
          avg_ajustment: '0.00',
          avg_total: '0.00',
          cant: 0,
          total: 0,
        },
      }),
    },
  },
  computed: {
    series() {
      return [
        {
          name: 'Adjustments',
          data: [this.data.values.ajustment],
        },
        {
          name: 'Average Adjustment',
          data: [parseFloat(this.data.values.avg_ajustment)],
        },
        {
          name: 'Total',
          data: [this.data.values.total],
        },
        {
          name: 'Gross production',
          data: [this.data.values.total + this.data.values.ajustment],
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: ['Values'],
        },
        title: {
          text: 'Appointments Data',
          align: 'center',
        },
      }
    },
  },
  mounted() {
    this.$refs.chart.updateOptions(this.chartOptions)
  },
}
</script>

<style scoped>

</style>
