<template>
  <b-container>
    <b-row>
      <b-col>
        <label>Facility</label>
        <treeselect
          v-model="filters.facilities_id"
          :async="true"
          :load-options="searchFacility"
          :defaultOptions="true"
          :normalizer="normalizer"
          multiple
          placeholder="Select Facilities" />
      </b-col>
      <b-col>
        <label>Start date</label>
        <flat-pickr
          v-model="filters.start_date"
          :config="config"
          class="form-control"
          placeholder="MM-DD-YYYY"
          type="date" />
      </b-col>
      <b-col>
        <label>End date</label>
        <flat-pickr
          v-model="filters.end_date"
          :config="config"
          class="form-control"
          placeholder="MM-DD-YYYY"
          type="date" />
      </b-col>
      <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
        <b-button variant="warning" @click="resetFilter" class="mr-1">Clear</b-button>
        <b-button variant="primary" @click="handleFormSubmit()" :disabled="loadingSearch">
          <span v-if="!loadingSearch">Search</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-row>
    <ToastNotification ref="toast" />
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BButton } from 'bootstrap-vue'
import SpinnerLoading from '@/components/SpinnerLoading'
import ToastNotification from '@/components/ToastNotification'
import axios from '@/core/services/api/admin/consults/membershipsDashboard'
import axiosF from '@/core/services/api/admin/facilities'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    SpinnerLoading,
    ToastNotification,
  },
  props: {
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      seeCuponsBool: false,
      loadingSearch: false,
      filters: {
        facilities_id: [],
        end_date: this.formatDate(new Date()),
        start_date: this.formatDate(new Date()),
      },
      config: {
        dateFormat: 'Y-m-d',
        enableSeconds: true,
        allowInput: true,
        enableTime: false,
      },
      tableData: {},
    }
  },
  created() {
    this.loadDefaultFacilities();
    this.handleFormSubmit();
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    loadDefaultFacilities() {
      axiosF.facilityFilter(10, {}).then(({ data }) => {
        this.filters.facilities_id = data.map(facility => facility.id);
      });
    },
    sendData(data) {
      this.tableData = data
      this.$emit('getItems', data)
    },
    handleFormSubmit() {
      this.loadingSearch = true;
      if (this.validateForm()) {
        this.searchFilter()
      }
    },
    validateForm() {
      const { start_date, end_date } = this.filters
      const isValid = start_date !== null && end_date !== null
      return isValid
    },
    facilitiesString() {
      return this.filters.facilities_id.join(', ')
    },
    searchFilter() {
      const filtersWithString = {
        ...this.filters,
        facilities_id: this.facilitiesString(),
      }

      if (!this.validateForm()) return null
      if (!this.filters.start_date || !this.filters.end_date) {
        return this.$refs.toast.warning('Check the dates!')
      }

      axios
        .membershipDashboard(filtersWithString)
        .then(({ data }) => {
          if (data) {
            this.$emit('dataReceived', data)
            this.loadingSearch = false;
          }
        })
        .catch(error => {
          this.loadingSearch = false;
          console.error('Error en la petición axios:', error)
        })
    },
    resetFilter() {
      const filter = this.filters
      for (const iterator in filter) {
        this.filters[iterator] = null
      }
    },
    searchFacility({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        const datos = {
          name: searchQuery,
        }
        axiosF.facilityFilter(10, datos).then(({ data }) => {
          // console.log('Facilities fetched:', data); // Debugging
          callback(null, data)
        })
        .catch(error => {
          console.error('Error fetching facilities:', error); // Debugging
          callback(error, null);
        });
      }
    },
  },
  computed: {
    normalizer() {
      return (node) => {
        return {
          id: node.id,
          label: node.name,
        }
      }
    },
  },
}
</script>

<style>
@media (min-width: 970px) {
  .separacion {
    display: none;
  }
}
</style>
